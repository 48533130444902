@font-face {
  font-family: 'Ubuntu';
  src: url("./font/Ubuntu-Regular.ttf");
}

html,
body,
.App {
  width: 100%;
  color: #ffffff;
}

html {
  font-family: "Ubuntu";
  background-color: #000000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background-position-x: center;
  background-position-y: bottom;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow-x: hidden;
}

html.font-KO {
  /* font-family: "맑은고딕"; */
}

html.font-VI {
  font-family: "Times New Roman";
}

html.font-ZH {
  /* font-family: "思源黑体"; */
  font-family: "思源宋体";
}

html.font-EN {
  font-family: "Ubuntu";
}

body {
  max-width: 1536px;
  min-height: 100%;
  font-size: medium;
  margin: auto;
}

ul {
  padding: 0;
}

a {
  text-decoration-line: none;
  color: unset;
}

a:link {
  color: unset;
}

a:visited {
  color: unset;
}

input,
select {
  border-radius: 24px;
  padding: 8px 12px;
  line-height: 1;
  background-color: #FFF;
  border: 1px solid #959595;
  flex-grow: 1;
  max-width: -webkit-fill-available;
}

select {
  padding: 8px 12px;
  padding-right: 32px;
  appearance: none;
  background: url(/public/img/dropArrow-black.png) no-repeat right 12px center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

select::after {
  content: '';
  display: block;
  clear: both;
}

button {
  border: 0;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  background: #000000;
  color: white;
  font-size: smaller;
  font-weight: bold;
  line-height: 1;
  padding: 12px 24px;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
}

button:disabled {
  pointer-events: none;
  opacity: 0.4;
  cursor: auto;
}

button.greenbutton {
  background-color: #00F0FF;
  opacity: 0.8;
  transition: all 0.2s;
}

button.greenbutton:hover {
  opacity: 1;
}

span {
  text-overflow: ellipsis;
  overflow: hidden;
}

xmp {
  margin: 0;
}

iframe {
  width: 100%;
  height: unset;
  aspect-ratio: 1.777 / 1;
  border-radius: 16px;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #3AA2DD;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.left,
.right {
  float: left;
  width: 50%;
}

.circle {
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  margin-right: 10px;
  aspect-ratio: 1 / 1;
}

.v-line {
  border-left: thin solid #fff;
  height: 100%;
}

.triangle {
  border-left: 10px solid #0000;
  border-right: 10px solid #0000;
  border-top: 20px solid #d9d9d9;
  height: 0;
  width: 0;
}

.whiteFont {
  color: white;
}

.contentText {
  padding: 20px 40px;
  color: white;
  line-height: 1.5;
  font-size: medium;
}

.address {
  word-break: break-word;
  padding: 8px 16px;
  text-align: center;
  font-weight: bold;
  border-radius: 6px;
  background-color: #000000;
  color: white;
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.copy-icon,
.copy-icon-black {
  aspect-ratio: 1 / 1;
  height: 24px;
  background-image: url(/public/img/copyIcon.png);
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.text-gradient {
  background: linear-gradient(90deg, #ff1cf7, #00f0ff);
  -webkit-background-clip: text;
  color: #0000;
}

#root {
  width: 100%;
  height: 100%;
}

#header {
  display: grid;
  margin: 24px auto;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: larger;
  gap: 16px;
  /* display: grid; */
  grid-template-areas:
    "tl tc tr"
    "bl bc br";
  grid-template-rows: auto auto;
  grid-template-columns: 224px auto 224px;
}

#header .icon-krypton {
  grid-area: tl;
  font-weight: bold;
  text-align: left;
  margin: 0;
}

#header .icon-krypton::before {
  content: '';
  background-image: url(/public/img/headerLogo.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 224px;
  aspect-ratio: 323 / 94;
}

#header .headerMenus {
  grid-area: tc;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 8px 16px;
  background: linear-gradient(90deg, rgba(5, 235, 254, 0.2), rgba(253, 29, 247, 0.2));
  border-radius: 24px;
  padding: 16px;
  min-width: 1024px;
}

#header .headerMenu_mobile {
  display: none !important;
  width: 100px;
  font-size: large;
  font-weight: bold;
  color: #000000;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  padding: 4px 0px;
}

#header .headerMenu_mobile.clicked {
  background-color: rgb(2, 213, 229);
}

#header .headerMenu {
  position: relative;
  display: flex;
  cursor: pointer;
  word-break: break-word;
  align-items: center;
}

#header .headerMenus.clicked {
  display: flex;
}

#header .headerMenu.selected {
  background: rgba(0, 0, 0, 0.4);
  padding: 12px;
  border-radius: 16px;
}

#header .headerMenu .headerSubMenu {
  position: absolute;
  display: none;
  margin-top: 32px;
  color: #888888;
  align-items: center;
}

#header .headerMenu.selected .headerSubMenu {
  display: flex;
  gap: 16px;
}

#header .headerSubMenu div {
  width: 100px;
}

#header .headerSubMenu div.selectedSub {
  color: #ffffff;
}

#header #lang-ui {
  grid-area: tr;
  position: relative;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

#header #lang-button {
  color: #ffffff;
  border: 0;
  border-radius: unset;
  background: url(/public/img/dropArrow.png) no-repeat right 8px center;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 16px;
  width: max-content;
  max-width: 224px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px;
  padding-right: 32px;
}

#header #lang-code {
  color: rgba(255, 255, 255, 0.5);
}

#header #lang-text {
  line-height: 1.5;
}

#header #lang-lists {
  display: none;
  margin: 0;
  padding: 0;
  width: max-content;
  max-width: 224px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  background: #000000;
  border-radius: 16px;
  border: 2px solid #ffffff;
}

#header #lang-lists li {
  list-style-type: none;
  display: flex;
  gap: 16px;
  padding: 8px 16px;
  margin: 8px;
  font-size: medium;
}

#header #lang-lists li.selected {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
}


#header #menu-mobile-button {
  display: none;
  background-image: url(/public/img/menu-mobile.png);
  background-repeat: no-repeat;
  border-radius: unset;
  padding: unset;
  width: 24px;
  background-size: contain;
  aspect-ratio: 7 / 5;
}

#homeBannerSpectrum {
  visibility: hidden;
}

#homeBannerContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: auto;
}

#homeBannerContent video {
  width: 100%;
}

#homeBannerContent .bannerButton {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-32px, -32px);
  animation: 0.8s ease 0s infinite alternate none running moveRootBannerButton;
}

#homeBannerContent .redirectButton {
  background: url(/public/img/rootBanner-button.png) no-repeat center;
  background-size: contain;
  aspect-ratio: 133 / 46;
  height: 92px;
  font-weight: bold;
  font-size: xx-large;
  display: block;
  text-align: center;
  align-content: center;
  line-height: 1;
}

@keyframes moveRootBannerButton {
  from {
    bottom: 0;
  }

  to {
    bottom: 24px;
  }
}

.homeBanner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: top;
  position: absolute;
}


@keyframes changeNextBanner {
  0% {
    transform: translateX(100%);
    visibility: visible;
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes changePresentBanner {
  from {
    transform: translateX(0%);
    visibility: visible;
  }

  to {
    transform: translateX(-100%);
  }
}

.homeBanner .bannerText {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.homeBanner .bannerTextContent {
  color: #bbbbbb;
  text-align: center;
  font-size: xxx-large;
}

.homeBanner .bannerButton {
  display: flex;
  height: 20%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.homeBanner .redirectButton {
  display: block;
  height: 75px;
  width: 100vw;
  margin-bottom: 50px;
  background-image: url("/public/img/rootBannerImg4_button.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  transform: translateX(-12.5%);
}

/* 
.contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  align-items: center;
  background: url("/public/img/homeBG.png"), #000000;
  background-position-x: center;
  background-position-y: 150px;
  background-attachment: fixed;
  background-repeat: no-repeat;
} */

.contentBox {
  width: 50%;
}

#home-contents {
  display: flex;
  flex-direction: column;
  gap: 128px;
  font-size: x-large;
  margin-bottom: 48px;
}

#home-contents .home-content-header {
  position: relative;
}

#home-contents .home-content-header-banner {
  width: calc(100% - 64px);
  margin: auto;
  background-image: url(/public/img/home-banner-EN-img1.gif);
  background-repeat: no-repeat;
  background-size: contain;
  aspect-ratio: 794 / 297;
}

#home-contents .home-content-header-button {
  position: absolute;
  left: 32px;
  bottom: -32px;
  display: flex;
  gap: 16px;
}

#home-contents .home-content-header-button-left {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

#home-contents .home-content-header-button-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#home-contents .home-content-header-button-left button {
  background: unset;
  background-image: url(/public/img/home-banner-button-left.png);
  aspect-ratio: 28 / 57;
  width: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}

#home-contents .home-content-header-button-right button {
  background: unset;
  background-image: url(/public/img/home-banner-button-right.png);
  aspect-ratio: 28 / 57;
  width: 32px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;
}

#home-contents .home-content-button {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 16px 24px;
  gap: 16px;
  font-weight: bold;
  justify-content: center;
}

#home-contents .home-content-button::after {
  content: '';
  width: 32px;
  aspect-ratio: 1 / 1;
}

#home-contents .home-content-header-button-mining {
  background-color: #ffffff;
  color: #000000;
}

#home-contents .home-content-header-button-mining::after {
  background: url(/public/img/home-header-mining.png) no-repeat center;
  background-size: contain;
}

#home-contents .home-content-header-button-wallet {
  background-color: #000000;
  border: 2px solid #ffffff;
}

#home-contents .home-content-header-button-wallet::after {
  background: url(/public/img/home-header-wallet.png) no-repeat center;
  background-size: contain;
}

#home-contents .home-content-body {
  display: flex;
}

#home-contents .home-content-body img {
  content: url(/public/img/home-center-img.png);
  aspect-ratio: 895 / 441;
  width: 100%;
}

#home-contents .home-content-body .right>div {
  margin-left: 64px;
}

#home-contents #home-content-body-read-more {
  border: 2px solid #ffffff;
  margin: 32px 64px;
}

#home-contents #home-content-body-read-more::after {
  background: url(/public/img/home-center-icon.png) no-repeat center;
  background-size: contain;
}

#home-contents #home-content-body-show {
  display: none;
}

#home-contents .home-content-tail {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 128px;
  position: relative;
  margin-bottom: 48px;
}

#home-contents .home-content-tail::before {
  position: absolute;
  content: '';
  width: 24px;
  height: 100%;
  background: linear-gradient(180deg, #E92EF800, #E92EF8 14%, #18DBFE 84%, #00F0FF00);
}

#home-contents .home-content-tail>div {
  display: flex;
  align-items: center;
  gap: 24px;
}

#home-contents .home-content-tail-title {
  justify-content: center;
  color: #B3B3B4;
  margin-bottom: 48px;
}

#home-contents .home-content-tail-index {
  background: url(/public/img/home-tail-index-img.png);
  aspect-ratio: 357 / 100;
  height: 48px;
  background-size: contain;
  text-align: right;
  color: #000000;
  font-weight: bold;
  align-content: center;
  background-repeat: no-repeat;
  place-content: center;
}

#home-contents .home-content-tail-index::after {
  content: '';
  margin-right: 16.5px;
}

#home-contents .home-content-tail-text {
  background: rgba(255, 255, 255, 0.2);
  padding: 24px;
  border-radius: 16px;
  flex-grow: 1;
  word-break: break-word;
}

#guide {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 48px;
  margin-bottom: 48px;
}

#guide .content {
  border: 2px solid #ffffff80;
  border-radius: 24px;
  flex-basis: 100%;
  padding: 48px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 24px;
  word-break: break-word;
}

#guide .guide-block-title {
  text-align: center;
  font-size: xx-large;
  margin-bottom: 24px;
  font-weight: bold;
}
#guide .guide-block .right>*:first-child {
  padding-top: 0px;
}
#guide .guide-block .right>* {
  padding-top: 24px;
  padding-left: 24px;
}

#guide #mining-block-detail img {
  content: url(/public/img/getCoin-mining-image-EN.png);
  width: 80%;
  margin-right: 24px;
  float: right;
}

.font-EN #mining-block-detail img {
  content: url(/public/img/getCoin-mining-image-EN.png) !important;
}

.font-KO #mining-block-detail img {
  content: url(/public/img/getCoin-mining-image-KO.png) !important;
}

.font-VI #mining-block-detail img {
  content: url(/public/img/getCoin-mining-image-VI.png) !important;
}

.font-ZH #mining-block-detail img {
  content: url(/public/img/getCoin-mining-image-ZH.png) !important;
}

#guide #mining-block-more,
#guide #mining-block-hide {
  border-bottom: 1px solid #2c2c2c;
  max-width: max-content;
  padding-bottom: 4px;
}

#guide .donationChart {
  align-items: center;
  display: flex;
  flex-direction: column;
}

#guide .donationIcon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  font-size: x-large;
}

#guide .donationIcon>div {
  display: flex;
  align-items: center;
}

#guide .donationBar {
  align-items: center;
  background: linear-gradient(135deg, #05EBFE, #FD1DF7);
  border-radius: 20px;
  color: #fff;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 64px;
  justify-content: space-evenly;
  margin-top: 20px;
  position: relative;
  text-align: center;
  width: 100%;
  word-break: break-word;
}

#guide .donationPrice {
  position: relative;
  width: calc(40% - 1px);
  height: 64px;
  align-content: center;
}

#guide .donationPrice:first-child {
  width: calc(20% - 1px);
}

#guide .donationPrice:last-child {
  width: calc(60% - 1px);
}

#guide .donationPrice::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(90deg, #00000000, rgba(0, 0, 0, 0.3));
}

#guide .triangle {
  left: 0px;
  position: absolute;
  top: -24px;
}

#guide .donationPrice-mobile {
  display: none;
  position: absolute;
  bottom: 8px;
  border-top: 1px solid #fff;
  width: 96%;
  margin: auto;
  padding-top: 4px;
  font-weight: bold;
}

#guide .sup-block {
  width: 70%;
  align-self: center;
  border: 2px solid #9747FF;
  border-radius: 16px;
}

#guide .sup-title {
  position: relative;
  background: #9747FF;
  width: calc(100% - 32px);
  display: inline-block;
  padding: 8px 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  text-align: left;
}

#guide .sup-title:after {
  position: absolute;
  content: unset;
  right: 16px;
  background-image: url("/public/img/referral-icon-hide.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  aspect-ratio: 1 / 1;
  top: 0;
  bottom: 0;
  place-self: center;
}

#guide .address {
  background-color: unset;
  justify-content: space-between;
  gap: 16px;
  font-weight: normal;
}

#guide .link-text {
  min-width: 96px;
  text-align: left;
}

#guide .referral-guide-image {
  width: 100%;
  border: unset;
  border-radius: unset;
  content: url('/public/img/referral-guide-EN-img.png');
}

.font-KO #guide .referral-guide-image {
  content: url('/public/img/referral-guide-KO-img.png');
}
.font-VI #guide .referral-guide-image {
  content: url('/public/img/referral-guide-VI-img.png');
}
.font-ZH #guide .referral-guide-image {
  content: url('/public/img/referral-guide-ZH-img.png');
}
.font-EN #guide .referral-guide-image {
  content: url('/public/img/referral-guide-EN-img.png');
}

#guide .referal-link-text {
  text-align: left;
  padding-right: 16px;
}

#guide .copy-block {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  background: linear-gradient(90deg, rgba(5, 235, 254, 0.2), rgba(253, 29, 247, 0.2));
  border-radius: 8px;
  gap: 8px;
  white-space: nowrap;
}

#guide .html-widgets-code {
  padding-right: 16px;
}

#guide xmp {
  text-align: left;
  white-space: pre-wrap;
}

#guide .referral-images {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 32px 8px;
  padding: 16px 0;
}

#guide .referral-images::before {
  content: '';
  margin: auto;
  width: 96%;
  height: 1px;
  background-color: #2C2C2C;
}

#guide .referral-images .selected {
  border: 4px solid #02B423;
  width: calc(20% - 8px);
}

#guide .referral-images>div {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 12px;
  padding: 8px;
}

#guide .referral-images .selected .referral-image {
  max-width: 192px;
}

#guide .referral-images .referral-image {
  width: 100%;
  max-width: 200px;
  aspect-ratio: 1 / 1;
  background-size: 100% 100%;
  border-radius: 12px;
}

#guide .referral-image-first .referral-image {
  background-image: url(/public/img/referral_img01_200px.gif);
}

#guide .referral-image-second .referral-image {
  background-image: url(/public/img/referral_img02_200px.gif);
}

#guide .referral-image-third .referral-image {
  background-image: url(/public/img/referral_img03_200px.gif);
}

#guide .referral-image-fourth .referral-image {
  background-image: url(/public/img/referral_img04_200px.gif);
}

#guide .html-widget-size {
  background-color: #ffffff;
}


#guide .sourceCode-block-detail .left {
  width: 60%;
}

#guide .sourceCode-block-detail .right {
  width: 40%;
}

#guide .sourceCode-item-list {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 48px;
  gap: 16px;
  flex-wrap: nowrap;
  word-break: break-word;
  border: 2px solid #9747FF;
  border-radius: 16px;
}

#guide .sourceCode-item-list .sourceCode-item-title {
  width: calc(100% - 32px);
  background: #9747FF;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 8px 16px;
  font-size: x-large;
}

#guide .sourceCode-item-list .sourceCode-item {
  border: 2px solid #ffffff;
  border-radius: 8px;
  font-size: large;
  padding: 16px;
  font-weight: bold;
  margin: 0 8px 16px 8px;
}

#guide .sourceCode-item-list .sourceCode-item.selected {
  border: unset;
  background-color: #ffffff;
  color: #000000;
}

#repository {
  position: relative;
  display: flex;
  width: max-content;
  background-color: #000000;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  gap: 24px;
  margin-bottom: 48px;
}

#repository .repository-element {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  padding: 32px;
  gap: 16px;
}


#repository .repository-title {
  font-size: xx-large;
  margin-bottom: 16px;
  font-weight: bold;
}

#repository .repository-block {
  width: calc(100% - 48px);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  padding: 24px;
  border-radius: 16px;
  background: linear-gradient(135deg, rgba(253, 29, 247, 0.1), rgba(5, 235, 254, 0.1)) #000000;
  gap: 32px;
}

#repository .repository-pdf-icon {
  background: url(/public/img/whitepaper-pdf-icon.png) center no-repeat;
  background-size: contain;
  width: 72px;
  aspect-ratio: 1 / 1;
}

#repository .repository-source-code-icon {
  background: url(/public/img/source-code-icon.png) center no-repeat;
  background-size: contain;
  width: 72px;
  aspect-ratio: 1 / 1;
}

#repository .repository-info-entry {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

#repository .repository-info-block {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  align-items: center;
}

#repository .repository-info-code {
  color: rgba(255, 255, 255, 0.5);
}

#repository .repository-download-icon {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: large;
  border-radius: 8px;
  padding: 0px 16px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  align-content: center;
  height: 40px;
}
#repository .repository-download-icon::after {
  content: '';
  background: url(/public/img/repository-download-icon.png) center no-repeat;
  background-size: contain;
  aspect-ratio: 37 / 27;
  height: 20px;
  display: inline-block;
}

#footer {
  display: grid;
  text-align: center;
  justify-content: start;
  align-items: center;
  font-weight: bold;
  font-size: larger;
  gap: 32px 64px;
  grid-template-areas:
    "tl tr"
    "cl cr"
    "bl br";    
  grid-template-rows: auto auto auto;
  grid-template-columns: 224px auto;
}

#footer .icon-krypton {
  grid-area: tl;
}
#footer .icon-krypton::before {
  content: '';
  background-image: url(/public/img/headerLogo.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 224px;
  aspect-ratio: 323 / 94;
}
#footer .footer-info {
  grid-area: tr;
}
#footer .icon-move-top {
  grid-area: cl;
  text-align: left;
  position: relative;
  height: 100%;
}
#footer .icon-move-top::before {
  content: '';
  background-image: url(/public/img/footer-scroll-up.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 80px;
  aspect-ratio: 1 / 1;
  position: absolute;
  bottom: 0;
}
#footer .footer-sns {
  grid-area: cr;
}
#footer .footer-list {
  max-width: max-content;
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #6F6D66;
  font-weight: normal;
}
#footer .footer-list p {
  font-size: medium;
}
#footer .footer-list-item {
  display: flex;
  flex-wrap: wrap;
  gap: 64px;
  border-top: 2px solid #403F3C;
  padding-top: 16px;
  font-size: x-large;
  align-items: center;
}
#footer .footer-list-item>div {
  display: flex;
  align-items: center;
  gap: 8px;
}
#footer .footer-list-item>div::before {
  content: '';
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 32px;
  aspect-ratio: 1 / 1;
}

#footer .footer-list-item-youtube::before {
  background-image: url(/public/img/footer-sns-youtube.png);
}
#footer .footer-list-item-discode::before {
  background-image: url(/public/img/footer-sns-discode.png);
}
#footer .footer-list-item-telegram::before {
  background-image: url(/public/img/footer-sns-telegram.png);
}
#footer .footer-list-item-facebook::before {
  background-image: url(/public/img/footer-sns-facebook.png);
}
#footer .footer-list-item-instagram::before {
  background-image: url(/public/img/footer-sns-instagram.png);
}
#footer .footer-list-item-threads::before {
  background-image: url(/public/img/footer-sns-threads.png);
}
#footer .footer-list-item-x::before {
  background-image: url(/public/img/footer-sns-x.png);
}
#footer .icon-background {
  grid-area: bl;
  position: relative;
  grid-column: span 2;
}
#footer .icon-background::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url(/public/img/footer-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  aspect-ratio: 640 / 263;
  transform: translateY(-50%);
}

@media (max-width: 1440px) {
  body {
    max-width: 1152px;
  }

  #header .headerMenus {
    min-width: 640px;
    font-size: medium;
  }

  #home-contents {
    font-size: large;
  }

  #home-contents .home-content-button {
    padding: 8px 16px;
    font-size: medium;
    gap: 8px;
  }

  #home-contents .home-content-button::after {
    width: 24px;

  }

  #homeBannerContent .redirectButton {
    height: 64px;
    font-size: x-large;
  }

  #guide #mining-block-detail img {
    width: 90%;
  }

  #guide .sup-block {
    width: 80%;
  }
  #footer .footer-list-item {
    font-size: large;
  }
}

@media (max-width: 1024px) {
  body {
    max-width: 816px;
  }

  #header .headerMenus {
    grid-area: bc;
    grid-column: 1 / span 3;
    padding: 8px;
    border-radius: 16px;
  }
  #home-contents .home-content-body {
    flex-direction: column;
    gap: 32px;
    font-size: medium;
  }
  

  #home-contents .home-content-body>div {
    width: 100%;
  }
  #home-contents .home-content-body .right>div {
    margin: 0;
  }
  #home-contents #home-content-body-read-more {
    margin: 16px 0;
    padding: 8px 48px;
  }

  #home-contents .home-content-button {
    padding: 6px 12px;
    font-size: small;
    border-radius: 8px;
  }

  #home-contents .home-content-header-banner {
    width: calc(100% - 48px);
  }

  #home-contents .home-content-header-button-left button,
  #home-contents .home-content-header-button-right button {
    width: 24px;
  }

  #home-contents .home-content-header-button {
    left: 24px;
    bottom: -24px;
  }

  #homeBannerContent .redirectButton {
    height: 48px;
    font-size: large;
  }
  #guide .guide-block>div {
    width: 100%;
  }
  #guide .guide-block .right>*:first-child {
    padding-top: 24px;
  }
  #guide .guide-block .right>* {
    padding-left: 0px;
  }

  #guide .referral-images>div {
    max-width: 40%;
    width: 200px;
  }

  #guide .referral-images .selected {
    width: 192px;
  }
}

@media (max-width: 768px) {
  body {
    max-width: 90%;
  }

  #guide .copy-block {
    background: unset;
    padding: 0;
    position: relative;
  }

  .copy-block span {
    display: none;
  }

  #header {
    flex-wrap: wrap;
    grid-template-areas:
      "tl tr"
      "bl br";
    grid-template-columns: 48px auto;
    gap: 8px 16px;
    margin: 8px auto;
  }

  #header .icon-krypton::before, #footer .icon-krypton::before {
    width: 192px;
  }

  #header .headerMenus {
    grid-area: bl;
    grid-column: 1 / span 2;
    min-width: unset;
    background: unset;
    padding: 0;
    justify-content: space-between;
    display: none;
    padding-bottom: 16px;
  }

  #header .headerMenus.collapsed {
    display: flex;
  }

  #header .headerMenu {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  #header .headerMenu::before {
    content: '';
    background-size: contain !important;
    width: 32px;
    aspect-ratio: 1 / 1;
  }

  #header .headerMenu_Home::before {
    background: url(/public/img/menu-icon-home.png) center no-repeat;
  }

  #header .headerMenu_Home.selected::before {
    background: url(/public/img/menu-icon-home-select.png) center no-repeat;
  }

  #header .headerMenu_Wallet::before {
    background: url(/public/img/menu-icon-wallet.png) center no-repeat;
  }

  #header .headerMenu_Explorer::before {
    background: url(/public/img/menu-icon-explorer.png) center no-repeat;
  }

  #header .headerMenu_How::before {
    background: url(/public/img/menu-icon-getcoin.png) center no-repeat;
  }

  #header .headerMenu_How.selected::before {
    background: url(/public/img/menu-icon-getcoin-select.png) center no-repeat;
  }

  #header .headerMenu_Repository::before {
    background: url(/public/img/menu-icon-whitepaper.png) center no-repeat;
  }

  #header .headerMenu_Repository.selected::before {
    background: url(/public/img/menu-icon-whitepaper-select.png) center no-repeat;
  }

  #header .headerMenu_OpenSource::before {
    background: url(/public/img/menu-icon-openSource.png) center no-repeat;
  }

  #header #menu-mobile-button {
    display: block;
  }

  #header #lang-lists {
    right: 40px;
  }

  #header .headerMenu.selected {
    padding: 0;
  }

  #homeBannerContent .bannerButton {
    transform: translate(-16px, -16px);
  }

  #homeBannerContent .redirectButton {
    height: 32px;
    font-size: small;
  }

  #home-contents {
    gap: 64px;
  }
  #home-contents .home-content-header-button-left, #home-contents .home-content-header-button-right {
    display: none;
  }

  #home-contents .home-content-header-banner {
    width: 100%;
    background-image: url(/public/img/home-header-mobile-img.png);
    aspect-ratio: 1 / 1;
  }

  #home-contents .home-content-header-button {
    width: 100%;
    justify-content: space-around;
    left: 0;
    bottom: 0;
  }

  #home-contents .home-content-button {
    min-width: 25%;
    font-size: medium;
    gap: 16px;
    border-radius: 12px;
  }
  #home-contents .home-content-button::after {
    width: 32px;
  }

  #home-contents .home-content-body img {
    content: url(/public/img/home-center-mobile-img.png);
  }

  #home-contents .home-content-tail-index {
    background-image: url(/public/img/home-tail-index-mobile-img.png);
    aspect-ratio: 116 / 47;
  }

  #home-contents .home-content-tail-text {
    font-size: medium;
    padding: 16px;
  }

  #home-contents .home-content-tail {
    margin-bottom: 0;
  }

  #guide .sup-title:after {
    content: '';
  }

  #guide .address {
    padding: 16px 32px;
  }

  #guide .copy-icon::before {
    content: '';
    position: absolute;
    top: 0%;
    left: -16px;
    height: 100%;
    border-left: 1px solid #ffffff;
  }

  #guide .widgetCode::before {
    top: -200%;
    height: 500%;
  }

  #guide #mining-block-detail {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .left,
  .right {
    float: left;
    width: 100%;
  }

  #guide .donationIcon {
    font-size: large;
    width: 100%;
  }

  #guide .donationBar {
    width: 100%;
    height: 48px;
  }

  /* #guide .donationPrice-mobile {
    display: block;
  } */
  /* #guide .donationBar {
      padding-bottom: 16px;
  } */
  #guide .sup-block {
    width: 100%;
  }

  #guide #mining-block-detail img {
    margin-right: 25%;
    width: 50%;
  }

  .font-EN #mining-block-detail img {
    content: url(/public/img/getCoin-mining-image-mobile-EN.png) !important;
  }

  .font-KO #mining-block-detail img {
    content: url(/public/img/getCoin-mining-image-mobile-KO.png) !important;
  }

  .font-VI #mining-block-detail img {
    content: url(/public/img/getCoin-mining-image-mobile-VI.png) !important;
  }

  .font-ZH #mining-block-detail img {
    content: url(/public/img/getCoin-mining-image-mobile-ZH.png) !important;
  }

  #repository {
    max-width: 100%;
    flex-direction: row;
  }
  #repository .repository-title {
    text-align: center;
    word-break: keep-all;
    font-size: x-large;
  }

  #repository .repository-block {
    flex-wrap: wrap;
    gap: 16px 32px;
    flex-direction: column;
    align-items: flex-start;
  }

  #repository .repository-download-icon {
    height: 32px;
    font-size: medium;
  }
  #repository .repository-download-icon::after {
    height: 16px;
  }
  #guide .sourceCode-block-detail .left, #guide .sourceCode-block-detail .right {
    width: 100%;
  }
  #guide .sourceCode-item-list {
    margin: 24px 0 0 0;
  }
  #footer .icon-background::before {
    background-image: url(/public/img/footer-mobile-bg.png);
  }
}

@media (max-width: 425px) {
  #header .headerMenus {
    gap: 4px;
  }

  .circle {
    height: 16px;
    margin-right: 8px;
  }

  #header .icon-krypton::before, #footer .icon-krypton::before {
    width: 136px;
  }

  #header .headerMenu {
    font-size: small;
  }

  #header .headerMenu::before {
    width: 24px;
  }

  #header #lang-button {
    font-size: x-small;
  }

  #header #lang-lists li {
    font-size: small;
  }

  #homeBannerContent .bannerButton {
    transform: translate(-8px, 0px);
  }

  #homeBannerContent .redirectButton {
    height: 24px;
    font-size: x-small;
  }

  #home-contents .home-content-header-button>button::after {
    width: 24px;
  }

  #home-contents .home-content-button {
    font-size: medium;
    padding: 8px 24px;
    gap: 8px;
  }

  #home-contents .home-content-body {
    font-size: medium;
  }

  #home-contents #home-content-body-read-more {
    font-size: medium;
  }

  #home-contents #home-content-body-read-more::after {
    width: 24px;
  }

  #home-contents .home-content-tail>div {
    gap: 16px;
  }

  #home-contents .home-content-tail-text {
    padding: 16px;
    font-size: medium;
  }
  
  #guide .referral-images {
    gap: 16px 8px;
  }

  #guide .referral-images .selected {
    max-width: calc(40% - 8px);
  }

  #guide #mining-block-detail img {
    margin-right: 15%;
    width: 70%;
  }

  #guide .referal-link-text {
    padding-right: 0;
  }

  #guide .html-widgets-code {
    padding-right: 0;
  }

  #guide .copy-icon::before {
    left: -8px;
  }

  #guide .address {
    padding: 12px 8px;
  }

  #guide .content {
    padding: 24px;
  }

  #guide .guide-block-title {
    margin-bottom: 0;
  }

  #guide .content:last-child .address {
    font-size: small;
  }

  #guide .donationIcon {
    font-size: medium;
  }

  #guide .donationPrice {
    font-size: small;
  }

  #guide .donationBar {
    height: 48px;
  }

  #repository {
    max-width: 90%;
    flex-direction: column;
    align-items: center;
  }
  #repository .repository-title {
    font-size: large;
  }
  #repository .repository-download-icon {
    font-size: small;
  }
  #repository .repository-element {
    padding: 16px;
    border-radius: 16px;
  }

  #repository .repository-block {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  #repository .repository-info-block {
    padding: 6px 12px;
  }
  #guide .sourceCode-item-list .sourceCode-item {
    font-size: medium;
  }
  #footer {
    gap: 32px 0;
    grid-template-areas:
    "tl"
    "tr"
    "cr"
    "cl"
    "bl";
    grid-template-rows: auto;
    grid-template-columns: auto;
  }
  #footer .footer-list-item {
    gap: 32px 64px;
  }
  #footer .icon-move-top::before {
    position:unset;
    width: 64px;
  }
}

@media (max-width: 375px) {
  #homeBannerContent .redirectButton {
    font-size: 8px;
  }

  #home-contents .home-content-tail>div {
    font-size: large;
    gap: 12px;
  }

  #home-contents .home-content-tail-text {
    padding: 12px;
    font-size: small;
  }

  #home-contents .home-content-tail-index {
    height: 32px;
  }

  #home-contents .home-content-tail-index::after {
    margin-right: 11px;
  }

  #guide #mining-block-detail img {
    margin-right: 10%;
    width: 80%;
  }
}

@media (max-width: 320px) {
  #header .icon-krypton::before, #footer .icon-krypton::before {
    width: 128px;
  }

  #header #lang-lists li {
    font-size: x-small;
  }

  #header .headerMenu {
    font-size: x-small;
  }

  #header .headerMenu::before {
    width: 16px;
  }

  #homeBannerContent .redirectButton {
    font-size: 6px;
    height: 16px;
  }

  #home-contents .home-content-button {
    padding: 6px 12px;
    gap: 6px;
    font-size: small;
  }

  #home-contents .home-content-header-button>button::after {
    width: 16px;
  }

  #home-contents .home-content-body {
    font-size: small;
  }

  #home-contents #home-content-body-read-more {
    font-size: small;
  }

  #home-contents #home-content-body-read-more::after {
    width: 16px;
  }

  #home-contents .home-content-tail-text {
    font-size: small;
  }

  #guide {
    font-size: small;
  }

  #guide .content {
    padding: 16px;
  }

  #guide .guide-block-title {
    font-size: x-large;
  }

  #guide #mining-block-detail img {
    margin-right: 5%;
    width: 90%;
  }

  #guide .donationIcon {
    font-size: small;
  }

  #guide .donationBar {
    height: 32px;
  }
  #guide .donationPrice {
    font-size: x-small;
  }
  #guide .sourceCode-item-list .sourceCode-item-title {
    font-size: large;
  }
  #guide .sourceCode-item-list .sourceCode-item {
    font-size: small;
  }
  #repository .repository-element {
    padding: 16px;
    border-radius: 16px;
  }
}